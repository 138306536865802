<template>
  <v-expansion-panels tile flat v-model="activePanel">
    <v-expansion-panel :key="0" style="background: #d1d4d9">
      <v-expansion-panel-header>
        <div class="d-flex full-width items-center">
          <BtnSquare
            icon="mdi-arrow-left"
            @click.native.stop="closeChat()"
            v-if="$vuetify.breakpoint.smAndDown"
            class="mr-1"
          />
          <template v-if="activeChat">
            <LivechatAvatar
              v-if="activeChat.user && activeChat.user.avatar"
              :avatar="activeChat.user.avatar"
            />
            <div class="ml-2">
              <h1 class="text-body-1 primary--text">
                {{ `${activeChat.user.avatar.color.name} ${activeChat.user.avatar.icon.name}` }}
              </h1>
              <div class="d-flex">
                <div
                  class="d-flex"
                  v-for="(item, index) in infoTags"
                  :key="index"
                >
                  <LivechatInfoTag :item="item" />
                  <v-divider
                    v-if="index < infoTags.length - 1"
                    vertical
                    class="mx-2"
                  />
                </div>
              </div>
            </div>
          </template>
        </div>
        <div>
          <v-icon v-if="activeChat?.notes">{{ infoCardIcon(infoTagTypes.note) }}</v-icon>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="activeChat" class="d-flex mb-2">
          <LivechatInfoSummary :active-chat="activeChat" ref="liveChatInfoSummary"/>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { mapGetters } from 'vuex';
import LivechatAvatar from '@/components/livechat/LivechatAvatar.vue';
import LivechatInfoTag from '@/components/livechat/LivechatInfoTag.vue';
import LivechatInfoFormTag from '@/components/livechat/LivechatInfoFormTag.vue';
import LivechatInfoCard from '@/components/livechat/LivechatInfoCard.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import { infoCardIcon, infoCardTypes, infoTagTypes } from '@/utils/livechat';
import LivechatInfoSummary from '@/components/livechat/LivechatInfoSummary.vue';

export default {
  name: 'LivechatInfo',
  components: {
    LivechatInfoSummary,
    LivechatAvatar,
    LivechatInfoTag,
    LivechatInfoCard,
    LivechatInfoFormTag,
    BtnSquare,
  },
  data() {
    return {
      activePanel: null,
      infoCardTypes,
      infoTagTypes,
      dialogAmount: 1,
      infoTags: [],
    };
  },
  computed: {
    ...mapGetters('bots', ['viewableBotId', 'getBot', 'currentBot']),
    ...mapGetters('livechat', ['activeChat', 'chatHistoryById', 'activeChatLanguage']),
  },
  watch: {
    activeChat() {
      this.loadInfos();
    },
    activePanel(newVal) {
      if (newVal === 0) this.refreshAttachmentsData();
    },
  },
  methods: {
    infoCardIcon,
    closeChat() {
      this.$store.commit('livechat/setIsChatOpen', false);
    },
    loadInfos() {
      if (!this.activeChat) {
        return null;
      }

      const botDetails = this.getBot(this.activeChat.uniqueBotId);
      const language = this.activeChatLanguage || "DE";
      const chatHistory = this.chatHistoryById(this.activeChat.uuid);

      const infoTags = [
        {
          type: infoTagTypes.channel,
          text: this.activeChat?.lastForm?.bot?.channelDisplay,
        },
        {
          type: infoTagTypes.language,
          text: language,
        },
        {
          type: infoTagTypes.link,
          // We use the last chat message to determine where they're talking from
          text: chatHistory.at(-1).url,
          href: chatHistory.at(-1).url,
        }
      ];

      // TBD
      // this.$store.dispatch('livechat/loadInfos', {
      //   uuid: this.activeChat.uuid,
      // });

      this.infoTags = infoTags;
    },
    refreshAttachmentsData() {
      if (this.$refs.liveChatInfoSummary) this.$refs.liveChatInfoSummary.init();
    },
  },
};
</script>
