import ApiService from './api';


/**
 * @typedef {Object} RAGCreationResult
 * @property {"ok"|"error"} status Either "ok" or "error"
 * @property {string} result A display message describing what happened.
 */
/**
 * @typedef {Object} RAGQueryOptions
 * @property {number} [topK] Limit results to the top matches
 * @property {Record<string, string>} [filter] Limit results to match records with given metadata
 */
/**
 * @typedef {Object & RAGArbitraryMetaData} RAGDocumentMetadata
 * @property {string} [page_label] The page this item is on, e.g. "1" or "2"
 * @property {string} intent The intent this document is linked to
 * @property {string} botId The bot this document is linked to
 * @property {string} url The URL to the resource (either website URL or URL to the PDF)
 * @property {RAGResourceType} type The type of resource, either "WEBSITE" or "PDF"
 * @property {?string} [sourceDocument] The name you provided if PDF, else the URL of the website
 * @property {string} createdAt The creation date as an ISO string
 * @property {string} ressourceId The resource ID this document belongs to
 * @property {string} _node_content The actual content of this document, e.g. some content from the PDF if a PDF Resource
 * @property {"TextNode"} _node_typ The type of node content. For now, only "TextNode", until more features are added
 * @property {string} document_id A unique ID of this document
 * @property {string} doc_id The ID of this document
 * @property {string} ref_doc_id Reference document ID
 */
/**
 * @typedef {Object} RAGDocument
 * @property {number} id The ID of this document
 * @property {string} text The content of this document, e.g. the content of the first page in a PDF
 * @property {RAGDocumentMetadata & RAGArbitraryMetaData} metadata Metadata about this document.
 * This object also merges in your {@link RAGArbitraryMetaData} object, so you can use that to store additional metadata, directly inside this object, alongside other data.
 */
/**
 * @typedef {Object} RAGResource
 * @property {string} _id Database object ID
 * @property {string} ressourceId The main ID of this resource, linking all docs together
 * @property {string[]} docIds List of document IDs
 * @property {string} botId The bot this resource is linked to
 * @property {string} intent The intent this resource is linked to
 * @property {RAGResourceType} type The type of resource, either "WEBSITE" or "PDF"
 * @property {string} url The URL to the resource (either website URL or URL to the PDF)
 * @property {Record<string, string>} metaData An object containing some arbitrary metadata, which can be used for categorizing and lookups
 * @property {number} numDocs The number of documents this resource contains. Might be higher than `docIds.length` by 1
 * @property {string|null} [comment] An optional comment that was attached
 * @property {string|null} [description] Describes this resource
 * @property {?RAGDocument[]} [docs] All the documents of this resource
 * @property {?string} [sourceDocument] The name of the source document if PDF, else the URL of the website
 * @property {RAGStatus} status The status of this resource, e.g. "active"
 */
/**
 * @typedef {Object} RAGQueryResponse
 * @property {"ok"|"error"} status Should be "ok" if all went well
 */
/**
 * @typedef {Object & RAGQueryResponse} RAGDeleteResponse
 * @property {{status: string, num_docs_deleted: number}} result The result of the deletion
 */
/**
 * @typedef {Object} RAGSourceUsed
 * @property {string} source The name of the source used, e.g. the filename if you used PDF
 * @property {RAGResourceType} type The type of source used, e.g. "PDF" or "WEBSITE"
 * @property {?string} [page] The page the source was from if PDF
 */
/**
 * @typedef {Object} RAGAnswerSourceContent
 * @property {string} ressourceId The RAG Resource ID (not ObjectID)
 * @property {string} text The actual text content
 * @property {RAGResourceType} type The type of resource this is
 * @property {?string} [url] The URL of the source (WEBPAGE)
 */
/**
 * @typedef {Object} RAGProtocol
 * @property {RAGResource[]} simDocs Similarity search results
 * @property {RAGAnswerSourceContent[]} usedKnowledge Information the LLM received as likely relevant for answering (used in prompt)
 * @property {RAGAnswerSourceContent[]} sources Which of the sources from `usedKnowledge` the LLM ended up using for the answer (used for answer)
 */
/**
 * @typedef {Object} RAGAnswer
 * @property {string} answer The actual text answer given by the AI
 * @property {RAGSourceUsed[]} sources The sources the AI used to arrive at this result
 * @property {RAGProtocol} ragProtocol The `ragProtocol` object for the answer generation
 */

const rag = (botId, path) => `/schaltzentrale/rag/bot/${botId}${path}`;

const RAGService = {
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {(RagPDF|RagWEBPAGE) & {type: RAGResourceType}} resource
   * @returns {Promise<RAGCreationResult>}
   */
  addResource(botId, intent, resource) {
    if (resource.type === "PDF") {
      return this.addPDFResource(botId, intent, resource);
    }

    if (resource.type === "WEBPAGE") {
      return this.addWebsiteResource(botId, intent, resource);
    }

    throw new Error(`Unknown resource type '${resource.type}'`);
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {RagPDF} RagPDF
   * @returns {Promise<RAGCreationResult>}
   */
  addPDFResource: async (botId, intent, RagPDF) => {
    let r;
    try {
      r = await ApiService.put(rag(botId, `/intent/${intent}/type/PDF`), RagPDF);
    } catch(e) {
      console.error(e);
      return null;
    }

    return r.data;
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {RagWEBPAGE} RagWEBPAGE
   * @returns {Promise<RAGCreationResult>}
   */
  addWebsiteResource: async (botId, intent, RagWEBPAGE) => {
    let r;
    try {
      r = await ApiService.put(rag(botId, `/intent/${intent}/type/WEBSITE`), RagWEBPAGE);
    } catch(e) {
      console.error(e);
      return null;
    }

    return r.data;
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @returns {Promise<RAGQueryResponse & {result: RAGResource[]}>}
   */
  getByIntent: async (botId, intent) => {
    try {
      return await ApiService.get(rag(botId, `/intent/${intent}/ressources`));
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} resourceID
   * @returns {Promise<RAGQueryResponse & {result: RAGResource}>}
   */
  getByResourceID: async (botId, resourceID) => {
    try {
      return await ApiService.get(rag(botId, `/ressource/${resourceID}/ressources`));
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @returns {Promise<void>}
   */
  deleteByIntent: async (botId, intent) => {
    throw new Error("To be implemented"); // No idea what the response is like here
    try {
      return await ApiService.delete(rag(botId, `/intent/${intent}`));
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} resourceID
   * @returns {Promise<RAGDeleteResponse>}
   * @throws {Error} If the response is not ok
   */
  deleteByResourceID: async (botId, resourceID) => {
    const r = await ApiService.delete(rag(botId, `/ressource/${resourceID}`));
    return r.data;
  },
  /**
   * @param {string} botId
   * @returns {Promise<RAGQueryResponse & {result: RAGResource[]}>}
   */
  getAllByBot: async (botId) => {
    try {
      return await ApiService.get(rag(botId, `/ressources`));
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @returns {Promise<void>}
   */
  deleteAllByBot: async (botId) => {
    throw new Error("To be implemented"); // No idea what the response is like here
    try {
      return await ApiService.delete(rag(botId, `/ressources`));
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {string} query
   * @param {?RAGQueryOptions} [options={}]
   * @returns {Promise<void>}
   */
  getSimilarDocs: async (botId, intent, query, options = {}) => {
    throw new Error("To be implemented"); // No idea what the response is like here
    try {
      return await ApiService.post(rag(botId, `/simdocs`), {
        intent,
        query,
        ... options,
      });
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Checks what the status of a document is.
   * Since the resource might not be ready, they will not have a resourceId, so we have to use URL
   * @param {string} botId
   * @param {string} intent
   * @param {string} resourceUrl
   * @returns {Promise<null | Omit<RAGStatus, 'draft'>>}
   */
  async checkResourceStatus(botId, intent, resourceUrl) {
    try {
      const r = await ApiService.get(rag(botId, `/intent/${intent}/status?url=${resourceUrl}`));
      return r.data?.result ?? null;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * @param {string} botId
   * @param {string} intent
   * @param {string} query
   * @param {?RAGQueryOptions} [options={}]
   * @returns {Promise<RAGQueryResponse & {result: RAGAnswer}>}
   */
  getAnswer: async (botId, intent, query, options = {}, channelId = null) => {
    try {
      let url = rag(botId, `/intent/${intent}/answer`);

      if (channelId) {
        url = rag(botId, `/intent/${intent}/channel/${channelId}/answer`);
      }

      const r = await ApiService.post(url, {
        query,
        ... options,
      });

      return r.data;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Retrieves questions that could not be answered by this RAG intent due to insufficient knowledge
   * @param {string} botId
   * @param {string} intent
   * @param {?string | null} [channelId = null] Channel to use
   * @returns {Promise<null | { question: string, created: string }[]>} `null` if an error occurred
   */
  async getMissingRAGKnowledge(botId, intent, channelId = null, page = 1, pageSize = 50) {
    try {
      let url = rag(botId, `/intent/${intent}/missing-knowledge`);
      if (channelId) {
        url = rag(botId, `/intent/${intent}/missing-knowledge?channelId=${channelId}`);
        url += `&page=${page}&pageSize=${pageSize}`;
      } else {
        url += `?page=${page}&pageSize=${pageSize}`;
      }

      const r = await ApiService.get(url);
      if (r.data?.data && r.data?.data.length > 0) {
        return r.data;
      }

      return {page: 1, data: r.data, total: r.data.length, pageSize: 50};
    } catch(e) {
      console.error(e);
      return null;
    }
  },
  /**
   * Retrieves RAG Resource Markdown for provided 'bot' and 'resourceId'
   * @param {{
   *  botId: string,
   *  resourceId: string,
   * }} params
   * @returns {Promise<{ md?: string }[]>}
   */
  async getRAGResourceMarkdown({ botId, resourceId }) {
    try {
      const url = rag(botId, `/ressource/${resourceId}/preview`);

      const data = await ApiService.get(url);
      return data.data?.result;
    } catch(e) {
      console.error(e);
      return null;
    }
  },
};

export default RAGService;
export { RAGService };