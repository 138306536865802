<template>
  <div class="info-card pl-2 pr-1 pt-2 pb-1">
    <div class="d-flex items-center">
      <div class="flex-grow-1">
        <div class="info-card-title d-flex items-center">
          <v-icon v-if="item.icon" small color="primary lighten-3" class="mr-1">
            {{ item.icon }}
          </v-icon>
          <span class="text-body-2 primary--text text--lighten-3">
            {{ item.title }}
          </span>
        </div>
        <div class="info-card-body pr-1">
          <div v-if="item.type === infoCardTypes.image">
            <div class="info-card-img mr-1"></div>
            <div class="info-card-img mr-1"></div>
          </div>
          <div v-else>
            {{ item.text }}
          </div>
        </div>
      </div>
      <v-btn
        v-if="item.downloadable"
        text
        class="pa-0 ml-1"
        style="min-width: 26px;"
        color="primary lighten-3"
        @click="handleDownload"
      >
        <v-icon small>mdi-download</v-icon>
      </v-btn>
      <v-btn
        v-if="item.lookable"
        text
        class="pa-0 ml-1"
        style="min-width: 26px;"
        color="primary lighten-3"
        @click="handleLook"
      >
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        v-if="item.copyable"
        text
        class="pa-0 ml-1"
        style="min-width: 26px;"
        color="primary lighten-3"
        @click="handleCopy"
      >
        <v-icon small>mdi-content-copy</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
/**
 * @typedef {Object} TLivechatInfoCard
 * @property {string} icon - The icon to display in the info card.
 * @property {string} title - The title of the info card.
 * @property {string} text - The text content of the info card.
 * @property {string} type - The type of the info card (e.g., 'image').
 * @property {boolean} downloadable - Whether the info card is downloadable.
 * @property {boolean} lookable - Whether the info card is lookable.
 * @property {boolean} copyable - Whether the info card is copyable.
 */

import { infoCardIcon, infoCardTypes } from '@/utils/livechat';

export default {
  name: 'LivechatInfoCard',
  props: {
    /**
     * @type {RecentlyCompletedFormStep}
     */
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { infoCardTypes };
  },
  methods: {
    handleDownload() {
      console.log('handleDownload');
    },
    handleLook() {
      console.log('handleLook');
    },
    handleCopy() {
      window.navigator.clipboard
        .writeText(this.item.text)
        .then(() => {
          console.log("Async: Copying to clipboard was successful!");
        })
        .catch(err => {
          console.error("Async: Could not copy text: ", err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.info-card {
  display: inline-block;
  background: white;
  border-radius: $spacer;
}

.info-card-title {
  height: $spacer * 4;
}
.info-card-body {
  // height: $spacer * 5;
}

.info-card-img {
  display: inline-block;
  height: 16px;
  width: 20px;
  border-radius: 2px;
  background: lightgray;
}
</style>
