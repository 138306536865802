<script>
/** @typedef {import('./LivechatInfoCard.vue').TLivechatInfoCard} TLivechatInfoCard */

import FilesService from '@/services/files';
import FileCard from '@/components/common/FileCard';
import LivechatInfoCard from '@/components/livechat/LivechatInfoCard.vue';
import LivechatInfoTag from '@/components/livechat/LivechatInfoTag.vue';
import { infoCardIcon, infoCardTypes, infoTagTypes } from '@/utils/livechat';

export default {
  name: 'LivechatInfoSummary',
  components: { FileCard, LivechatInfoTag, LivechatInfoCard },
  props: {
    "activeChat": {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      infoCardTypes,
      infoTagTypes,
      attachmentItems: [],
    };
  },
  async created() {
    this.init();
  },
  computed: {
    formattedFormSteps() {
      /** @type {TLivechatInfoCard} */
      const items = [];

      for (let i = 0; i < this.activeChat.lastForm?.steps?.length; i++) {
        if (!this.activeChat.lastForm?.steps[i].contextValue) {
          continue;
        }

        /**
         * @type {RecentlyCompletedFormStep}
         */
        const step = this.activeChat.lastForm.steps[i];

        /**
         * Some steps may require some logic to fish out the value to display
         * @param {RecentlyCompletedFormStep} step
         * @returns {string|number}
         */
        const extractValue = (step) => {
          if (step.rule === "$email" && Array.isArray(step.contextValue)) {
            return step.contextValue[0];
          }

          return step.contextValue;
        }

        items.push({
          title: step.stepName,
          text: extractValue(step),
          type: this.typeForTemplate(step.template),
          copyable: this.isCopyable(step.template),
          icon: this.iconForTemplate(step),
        });
      }

      return items;
    },
    liveChatAttachment() {
      if (!this.attachmentItems?.length) return [];

      const formattedFormStepsTextSet = new Set(this.formattedFormSteps.map((step) => step.text));
      const liveChatAttachment = [];
      
      this.attachmentItems.forEach((event) => {
        const url = FilesService.generateFileEventLink(event);
        // Skip Files that are already in the form steps
        if (formattedFormStepsTextSet.has(url)) return;

        liveChatAttachment.push({
          title: 'LiveChat Attachment',
          text: url,
          type: 'attachment',
          copyable: true,
        });
      });

      return liveChatAttachment;
    },
  },
  methods: {
    infoCardIcon,
    /**
     * Returns the infoTagType/infoCardType for a given template used in a step
     * @param {RecentlyCompletedFormStep["template"]} template
     * @returns {string}
     */
    typeForTemplate(template) {
      switch(template) {
        case "BotFormEmail":
          return infoCardTypes.email;
        case "BotFormFreeText":
        case "BotFormValidableInput":
          return infoCardTypes.text;
        // Redundant, but lets you know what options we have:
        case "BotFormYesNo":
        case "BotFormMultipleChoice":
          return infoCardTypes.text;
        default:
          return infoCardIcon("unknown");
      }
    },
    /**
     * Returns the infoTagType/infoCardType for a given template used in a step
     * @param {RecentlyCompletedFormStep} step
     * @returns {string}
     */
    iconForTemplate(step) {
      switch(step.template) {
        case "BotFormEmail":
          return infoCardIcon(infoCardTypes.email);
        case "BotFormFreeText":
          return infoCardIcon(infoCardTypes.text);
        case "BotFormValidableInput":
          /**
           * These are the keys you can select in a BotFormValidableInput (see `validableTypes`).
           * From the completed form endpoint they're prefixed with a '$'.
           */
          switch(step.rule) {
            case "$email":
              return infoCardIcon(infoCardTypes.email);
            case "$zipcode":
            case "$number":
              return infoCardIcon(infoCardTypes.number);
            case "$url":
              return infoCardIcon(infoCardTypes.link);
            case "$date":
              return infoCardIcon(infoCardTypes.date);
            case "$phone":
              return infoCardIcon(infoCardTypes.phone);
            case "$licenceplate": // Beware of spelling with this one
              return infoCardIcon(infoCardTypes.licenseplate);
            case "$iban":
              return infoCardIcon(infoCardTypes.iban);
            case "$regexp":
              return infoCardIcon(infoCardTypes.regexp);
            default:
              return infoCardIcon(infoCardTypes.text);
          }
        // Redundant, but lets you know what options we have:
        case "BotFormYesNo":
        case "BotFormMultipleChoice":
          return infoCardIcon(infoCardTypes.text);
        default:
          return infoCardIcon("unknown");
      }
    },
    /**
     * Determines whether you can copy the value in this step
     * @param {RecentlyCompletedFormStep["template"]} template
     * @returns {boolean}
     */
    isCopyable(template) {
      switch(template) {
        case "BotFormEmail":
        case "BotFormValidableInput":
          return true;
        // Redundant, but lets you know what options we have:
        case "BotFormYesNo":
        case "BotFormFreeText":
        case "BotFormMultipleChoice":
          return false;
        default:
          return true;
      }
    },
    async checkForFiles() {
      if (!this.activeChat?.uniqueBotId || !this.activeChat?.uniqueUserId) return;

      const filesList =
        (
          await FilesService.checkFileAccess({
          botId: this.activeChat?.uniqueBotId,
          uuId: this.activeChat?.uniqueUserId,
          })
        )?.data?.events || [];

      this.attachmentItems = filesList;
    },
    async init() {
      await this.checkForFiles();
    },
  },
  watch: {
    activeChat() {
      this.checkForFiles();
    },
  }
};
</script>

<template>
  <v-row>
    <div class="px-2 pb-2 grow max-width-full scrollable-content">
      <div class="primary--text text--lighten-2 mb-1">
        <v-icon size="16" color="primary lighten-2">
          {{ infoCardIcon(infoTagTypes.form) }}
        </v-icon>
        {{ activeChat.lastForm.displayName }}
      </div>

      <div class="d-flex gap-2 flex-wrap mb-4">
        <LivechatInfoCard
          v-for="(step, i) in formattedFormSteps"
          :key="i"
          :item="step"
        />
      </div>

      <div class="d-flex gap-2 flex-wrap mb-4">
        <LivechatInfoCard
          v-for="(step, i) in liveChatAttachment"
          :key="i"
          :item="step"
        />
      </div>

      <template v-if="attachmentItems?.length">
        <h2 class="text-h6">{{ $t('common.attachments') }}</h2>
        <div>
          <FileCard
            border
            v-for="(item, index) in attachmentItems"
            :key="index"
            :item="item"
          />
        </div>
      </template>

      <div v-if="activeChat.notes" class="full-width pa-2 internal-note primary--text text-lighten-3">
        <div class="d-flex align-center text-sm-body-2">
          <v-icon small left color="primary lighten-3">
            {{ infoCardIcon(infoTagTypes.note) }}
          </v-icon>
          <span>Internal note</span>
        </div>
        {{ activeChat.notes }}
      </div>
    </div>
  </v-row>
</template>

<style scoped lang="scss">

.internal-note {
  border: solid thin #788bab80;
  border-radius: 0.25rem;
}

.scrollable-content{
  max-height: 25vh;
  overflow-y: scroll;
  overflow:auto
}

</style>