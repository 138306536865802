<template>
  <div>
    <HubDialog
      v-model="isDialogOpen"
      :title="$t('editor.samples.add')"
      primary-icon="save"
      @onPrimary="addNewSample"
      :primary-label="$t('common.add')"
      secondary-icon="close"
      @onSecondary="toggleDialog(false)"
      :secondary-label="$t('common.cancel')"
      :loading="isLoading"
      :disabled="isLoading || !isNewValueValid()"
      asyncDialog
      modal
    >
      <div class="pt-10">
        <v-row>
          <v-col>
            <v-textarea
              :label="$tc('editor.samples.samples', 1)"
              outlined
              v-model="newSample"
              :rules="rules.newSampleRule"
              rows="5"
            />
          </v-col>
        </v-row>
      </div>
    </HubDialog>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';
import HubDialog from '@/components/hub/HubDialog.vue';
import BtnSquare from '@/components/common/BtnSquare.vue';
import ProductService from '@/services/product';

export default {
  name: "AddSampleDialog",
  components: {
    HubDialog,
    BtnSquare
  },
  data() {
    return {
      isLoading: false,
      newSample: '',
      samplesList: [], // All samples collected together as [Object]
      originTrainingSamples : [],
      rules: {
        newSampleRule: [
          (v) => (v || '').length >= 0 || this.$t("integrations.rules.minLength", {count: 0}), // At least 1 symbol | But we don't want to show an error message that will disturb the User (so we handle this in save logic)
          (v) => (v || '').length <= 160 || this.$t("integrations.rules.maxLength", {count: 160}),
          (v) => (!this.samplesList?.map(e => e.text)?.includes(v?.trim())) || this.$t("editor.samples.rules.notSame"),
        ]
      }
    };
  },
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    intent: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('intents', ['intents', 'suggested']),
    ...mapGetters('bots', ['currentBot', 'currentBotId']),
  },
  methods: {
    /**
     * Handles open/close
     * @param {boolean} value 
     * @example
     * ```vue
     *  <AddSampleDialog
     *    :intent="intent"
     *    :isDialogOpen="isAddSampleOpen"
     *    @update:isDialogOpen="isAddSampleOpen = $event"
     *  />
     * ```
     */
    toggleDialog(value) {
      this.$emit('update:isDialogOpen', value);
    },
    isNewValueValid() {
      return this.rules.newSampleRule?.every(rule => rule(this.newSample?.trim()) === true);
    },
    async addNewSample() {
      this.isLoading = true;

      this.newSample = this.newSample?.trim();
      const isValid = this.isNewValueValid();
      
      if (!this.newSample || !isValid || !this.intent) {
        this.isLoading = false;
        return;
      };

      const result = await ProductService.nlpFeedback(this.currentBotId, this.newSample, true, this.intent.intent);
      if (result?.status !== 'ok') {
        console.error('Something went wrong when saving Sample. Saving result is:', result);
        this.isLoading = false;
        return;
      }

      // ? Refresh training data | Uncomment if training data can be also updated here
      // await this.fetchTrainingData();

      await this.refreshSamplesList();

      this.newSample = '';
      this.isLoading = false;

      // On success
      this.toggleDialog(false);
      this.$emit('onSave');
    },
    // Samples are AiFeedback(s)
    async fetchSamples() {
      const { data } = await ProductService.getNlpFeedbacks(this.currentBotId, this.intent.intent);
      const aiFeedbackSamples = Array.isArray(data) ? data : [];
      return aiFeedbackSamples;
    },
    async fetchTrainingData() {
      const data = await this.$store.dispatch('trainingdata/get', {
        uniqueBotId: this.currentBotId,
        name: this.intent.intent
      });

      const trainingSamples = Array.isArray(data) ? data : [];

      this.originTrainingSamples = trainingSamples;
      return _.cloneDeep(trainingSamples);
    },
    async refreshSamplesList() {
      // Get previously fetched trainingData
      let updatedList = _.cloneDeep(this.originTrainingSamples);

      const aiFeedback = await this.fetchSamples();
      if (aiFeedback?.length) {
        updatedList = updatedList.concat(aiFeedback);
      }

      this.samplesList = _.cloneDeep(updatedList);
      return updatedList;
    },
  },
  async beforeMount() {
    await this.fetchTrainingData();
    await this.refreshSamplesList();
  },
};
</script>
