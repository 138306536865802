var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "text-subtitle-1" }, [
        _vm._v(_vm._s(_vm.$t("botFormEdit.fileUpload.allowedFileTypes"))),
      ]),
      _c(
        "p",
        { staticClass: "text-subtitle-2" },
        [
          _c("v-select", {
            attrs: {
              items: _vm.mimeTypes,
              "item-text": "title",
              "item-value": "mime",
              multiple: "",
              chips: "",
              label: _vm.$t("botFormEdit.fileUpload.allowedFileTypes"),
              hint: _vm.$t(
                "botFormEdit.fileUpload.allowedFileTypesDescription"
              ),
              "persistent-hint": "",
              outlined: "",
            },
            on: {
              change: _vm.allowedTypesChanged,
              "hook:mounted": _vm.initMimeTypes,
            },
            model: {
              value: _vm.allowedTypes,
              callback: function ($$v) {
                _vm.allowedTypes = $$v
              },
              expression: "allowedTypes",
            },
          }),
        ],
        1
      ),
      _vm.isAdmin
        ? _c(
            "v-row",
            {
              staticClass:
                "ma-0 d-flex justify-start align-center moin-admin-box px-2",
            },
            [
              _c(
                "v-col",
                [
                  _c("label", { staticClass: "mr-3" }, [
                    _vm._v(
                      _vm._s(_vm.$t("botFormEdit.fileUpload.maxFilesTemplate"))
                    ),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      value: _vm.maxFiles,
                      label: _vm.$t("botFormEdit.fileUpload.maxFilesLabel"),
                      type: "number",
                      step: "1",
                      min: _vm.fileCountLimits.min,
                      max: _vm.fileCountLimits.max,
                    },
                    on: { input: _vm.setMaxFileCount },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }