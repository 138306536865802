<template>
  <div>
    <span class="text-subtitle-1">{{ $t('botFormEdit.fileUpload.allowedFileTypes') }}</span>
    <p class="text-subtitle-2">
      <!-- vuetify select box that can have multiple selection of mimeTypes, model changed call allowedTypesChanged, if component mounted call initMimeTypes -->
      <v-select
        v-model="allowedTypes"
        :items="mimeTypes"
        item-text="title"
        item-value="mime"
        multiple
        chips
        :label="$t('botFormEdit.fileUpload.allowedFileTypes')"
        :hint="$t('botFormEdit.fileUpload.allowedFileTypesDescription')"
        persistent-hint
        outlined
        @change="allowedTypesChanged"
        @hook:mounted="initMimeTypes"
      ></v-select>
    </p>
  
    <v-row
      v-if="isAdmin"
      class="ma-0 d-flex justify-start align-center moin-admin-box px-2"
    >
      <v-col>
        <label class="mr-3">{{ $t('botFormEdit.fileUpload.maxFilesTemplate') }}</label>
        <v-text-field
          :value="maxFiles"
          :label="$t('botFormEdit.fileUpload.maxFilesLabel')"
          type="number"
          step="1"
          :min="fileCountLimits.min"
          :max="fileCountLimits.max"
          @input="setMaxFileCount"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

/**
 * BotFormFileEditBase component allows users to configure file upload settings.
 * 
 * @component
 * @name BotFormFileEditBase
 * 
 * @props {Object} options - Configuration options for file upload.
 * @props {Array} options.acceptedFiles - List of accepted file types.
 * @props {Boolean} options.generateTokens - Flag to generate tokens.
 * @props {Number} options.maxFiles - Maximum number of files allowed.
 * @props {Number} options.maxFilesize - Maximum file size allowed.
 * @props {Boolean} options.uploadMultiple - Flag to allow multiple file uploads.
 */
export default {
  name: 'BotFormFileEditBase',
  components: {},
  props: {
    options: {
      type: Object,
      default: () => ({
        acceptedFiles: ['image/*'],
        generateTokens: false,
        maxFiles: 3,
        maxFilesize: 50_000,
        uploadMultiple: false,
      }),
    },
  },
  data: () => ({
    allowedTypes: [], // File types that are allowed to be uploaded
    maxFiles: 1,
    maxFilesize: 50_000,
    uploadMultiple: false,
    /**
     * Should be changed to use data from API?
     * It will tell us max possible files we can set
     * for the given context (context being channel, etc.)
     *
     * For now, we define hard limit to 10.
     */
    fileCountLimits: {
      min: 1,
      max: 10,
    },
  }),
  computed: {
    ...mapGetters('bots', ['currentBotId', 'currentChannels', 'getBotSettings']),
    ...mapGetters('auth', ['isAdmin']),
    mimeTypes() {
      // Erstelle ein leeres Array
      let array = [];
      // Füge jedem Dateinamen den entsprechenden MIME-Typ und Titel hinzu
      array.push({filename: ".pdf", mime: "application/pdf", title: "PDF"});
      array.push({filename: ".zip", mime: "application/zip", title: "ZIP"});
      array.push({filename: ".xml", mime: "application/xml", title: "XML"});
      array.push({filename: ".txt", mime: "text/plain", title: "TXT"});
      array.push({filename: ".csv", mime: "text/csv", title: "CSV"});
      array.push({filename: ".doc/docx", mime: "application/msword / application/vnd.openxmlformats-officedocument.wordprocessingml.document", title: "MS Word"});
      array.push({filename: ".xls/xlsx", mime:"application/vnd.ms-excel / application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" , title:"MS Excel"});
      array.push({filename:".ppt/pptx" , mime:"application/vnd.ms-powerpoint / application/vnd.openxmlformats-officedocument.presentationml.presentation" , title:"MS PowerPoint"});
      array.push({filename:".png" , mime:"image/png" , title:"PNG"});
      array.push({filename:".jpg" , mime:"image/jpeg" , title:"JPEG"});
      array.push({filename:".gif" , mime:"image/GIF" , title:"GIF"});

      return array;
    },
  },
  methods: {
    update() {
      this.$emit('onUpdated');
    },
    allowedTypesChanged() {
      // If the allowedTypes array contains 'image/*' AND other types were selected, remove 'image/*'
      if (this.allowedTypes.filter(type => type !== 'image/*').length > 0) {
        this.allowedTypes = this.allowedTypes.filter(type => type !== 'image/*');
      }

      // ? Do we need to implement some Default mime type that will be used, when the array is empty?

      this.options.acceptedFiles = this.allowedTypes;
      this.update();
    },
    initMimeTypes() {
      if (this.options?.acceptedFiles) {
        this.allowedTypes = this.options.acceptedFiles;
      }
    },
    /**
     * Captures the max files input, clamps it to min/max, then sets the value
     * @param {number} newCount
     */
    setMaxFileCount(newCount) {
      if (!newCount) newCount = this.fileCountLimits.min;

      this.maxFiles = Math.max(Math.min(newCount, this.fileCountLimits.max), this.fileCountLimits.min);

      this.uploadMultiple = this.maxFiles > 1 ? true : false;

      this.options.maxFiles = this.maxFiles;
      this.options.uploadMultiple = this.uploadMultiple;

      this.update();
    },

    async init() {
      if (!this.options.acceptedFiles || !this.options.acceptedFiles?.length) this.options.acceptedFiles = ['image/png'];
      if (!Array.isArray(this.options.acceptedFiles)) {
        // Not sure how it was working before with 'image/*' as string but not an array, but to be able to Select something in v-select component this should be an array
        console.warn(`[BotFormFileEditBase]: 'acceptedFiles' should be an array, but got "${this.options.acceptedFiles}". Converting to array.`);
        this.options.acceptedFiles = [this.options.acceptedFiles];
        this.update();
      }

      // Fields that will be defined anyway as default values
      this.maxFiles = this.options.maxFiles || this.fileCountLimits.min;
      if (this.options.maxFilesize) this.maxFilesize = this.options.maxFilesize; // Optional because this parameter does not have an UI Element
      this.uploadMultiple = this.options.uploadMultiple;
    },
  },
  watch: {},
  created() {
    this.init();
  }
};
</script>
