<template>
  <div class="d-flex align-end">
    <div class="align-self-center d-flex">
      <DropDown :items="chatDropdownItems" :autoClose="true">
          <template #actuator="{ toggle }">
            <v-btn
              icon
              @click="toggle"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
      </DropDown>
    </div>
    <div class="message-field-wrapper">
      <LivechatShortcodesList
        :filter="shortcodeFilter"
        :show="showShortcodesPanel"
        @select="onShortcodeSelected"
        ref="shortcodesListRef"
      />
      <div class="chat-input-wrapper">
        <div class="input-top-info pa-1" v-if="files.length > 0">
          <v-chip
            v-for="file in files"
            close
            small
            @click:close="removeFile(file)"
            class="mr-1 grey lighten-3"
            :key="file.url"
          >
            {{ file.name }}
          </v-chip>
        </div>
        <div class="chat-input">
          <v-textarea
          rows="1"
          solo
          flat
          hide-details
          auto-grow
          dense
          v-model="message"
          :disabled="disabled"
          @input="onInput"
          @keydown="onKeyDown"
          @keypress.enter.exact="sendMessage"
          ref="messageInputRef"
        ></v-textarea>
        <div class="input-bottom-info">
          <slot name="bottom-info">
            <div v-if="useTranslation && !translationServiceError">
              <v-icon small class="pr-1">mdi-web</v-icon>
              <span v-if="!inputTranslation">
                {{ $t('livechat.chats.autoTranslationHint', {lang: $t(`common.languageNames.${activeChatLanguage?.toLowerCase()}`)}) }}
              </span>
              <span v-else>{{ inputTranslation }}</span>
            </div>
            <div v-if="translationServiceError">
              <v-icon small class="pr-1" color="red lighten-1">mdi-alert-circle</v-icon>
              <span class="red--text text--lighten-1">{{ $t('livechat.chats.autoTranslationErrorHint') }}</span>
            </div>
          </slot>
        </div>
      </div>
    </div>
    </div>
    <v-btn
      icon
      class="primary lighten-2 ml-2"
      color="white"
      @click="sendMessage()"
    >
      <v-icon>mdi-send</v-icon>
    </v-btn>
    <LivechatFilesModal
      :open="showFilesModal"
      @cancel="onFilesModalCancel"
      @add="onFilesModalAdd"
    />
    <LivechatNotesModal
      :open="showNotesModal"
      @cancel="onNotesModalCancel"
      @add="onNotesModalAdd"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import TranslationService from '@/services/translation';
import LivechatShortcodesList from './LivechatShortcodesList.vue';
import LivechatFilesModal from './LivechatFilesModal.vue';
import LivechatNotesModal from './LivechatNotesModal.vue';
import DropDown from '@/components/common/dropdown/DropDown.vue';

export default {
  name: 'LivechatChatInput',
  components: {
    LivechatShortcodesList,
    LivechatFilesModal,
    LivechatNotesModal,
    DropDown,
  },
  computed: {
    ...mapGetters('livechat', ['agent', 'activeChat', 'useTranslation', 'activeChatLanguage', 'translationServiceError']),
    ...mapGetters('bots', ['currentBotId']),
    chatDropdownItems() {
      return [
        {
          icon: 'mdi-file-document-outline',
          label: 'Files',
          onClick: () => this.showFilesModal = true,
        },
        {
          icon: 'mdi-code-tags',
          label: 'Shortcodes',
          onClick: this.initShortcodePanel,
        },
        {
          icon: 'mdi-note-plus-outline',
          label: 'Notes',
          onClick: () => this.showNotesModal = true,
        },
      ];
    },
  },
  data() {
    return {
      message: '',
      disabled: false,
      inputTranslation: null,
      translateDebounce: null,
      isTypingCooldown: null,
      showShortcodesPanel: false,
      shortcodeFilter: '',
      showFilesModal: false,
      showNotesModal: false,
      /**
       * @typedef {Object} File
       * @property {string} name
       * @property {string} url
       * @property {string} description
       * @property {string} group
       */
      /** @type {File[]} files */
      files: [],
    };
  },
  created() {
    this.translateDebounce = _.debounce(async function(msg) {
      if (this.useTranslation && msg && msg.trim()) {
        const res = await TranslationService.getTranslation(this.currentBotId, msg.trim(), this.activeChatLanguage);
        if (res && res.status === "ok") {
          this.inputTranslation = res.translation.text;
        }
      } else {
        this.inputTranslation = null;
      }
    }, 1700);
  },
  watch: {
    activeChat() {
      this.resetInput();
    },
  },
  methods: {
    async sendMessage(e) {
      e?.preventDefault();
      this.message = this.message.trim();

      if (!this.message && !this.files.length) {
        return;
      }
      const data = {
        data: this.message,
        type: 'message',
        agentUuid: this.agent.uuid,
        chatUuid: this.activeChat.uuid,
        sender: {
          role: 'agent',
          uniqueId: this.agent.uuid,
        },
        created_at: new Date() + '',
        updated_at: '',
        attachments: this.files.map(f => ({
          name: f.name,
          url: f.url,
        }))
      };
      try {
        this.disabled = true;
        await this.$store.dispatch('livechat/addMessage', data);
        this.resetInput();

      } catch (err) {
        console.error('sending message failed', err);
      }
      
      this.disabled = false;
    },
    resetInput() {
      this.message = String('');
        this.inputTranslation = null;
        this.translateDebounce.cancel();
        this.files = [];
        this.$nextTick(() => {
          this.$refs.messageInputRef.focus();
        });
    },
    async sendIsTyping(isTyping = true) {
      const data = {
          // direct: false, // Send directly to widget (not saved in history | dialogProtocol)  | (true as default) | !!! Currently NOT supported by moin-bot
          // noHistory: false, // Send only message without history | (true as default)
          cmd: 'typing', // intent name in case if direct: false
          payload: {
            typing: isTyping,
        },
      };

      await this.$store.dispatch('livechat/addWebChatCmd', data);
    },
    async handleTyping() {
      const timeoutSec = 5;
      // Set timeout
      if (!this.isTypingCooldown) {
        this.sendIsTyping();
        this.isTypingCooldown = setTimeout(() => {
          this.sendIsTyping(false);
          this.isTypingCooldown = null;
        }, timeoutSec * 1000);
        return;
      }

      // Reset timeout | if already defined
      clearTimeout(this.isTypingCooldown);
      this.isTypingCooldown = setTimeout(() => {
        this.sendIsTyping(false);
        this.isTypingCooldown = null;
      }, timeoutSec * 1000);

      // Reset timeout to 3 and after that => setTyping  off
    },
    onInput() {
      this.handleTyping();

      // if the message contains only a "/" open the shortcodes pannel
      if (this.message?.indexOf('/') === 0) {
        this.showShortcodesPanel = true;
        this.shortcodeFilter = this.message.substring(1);
      } else {
        this.showShortcodesPanel = false;
        this.shortcodeFilter = '';
      }

      // translate message
      this.inputTranslation = this.$t('livechat.chats.translating');
      this.translateDebounce(this.message);
    },
    initShortcodePanel() {
      if (!this.showShortcodesPanel) {
        this.message = '/';
        this.showShortcodesPanel = true;
        this.$nextTick(() => {
          this.$refs.messageInputRef.focus();
        });
      }
    },
    onKeyDown(event) {
      const up = 38;
      const down = 40;
      const esc = 27;
      const enter = 13;
      if (this.showShortcodesPanel && esc == event.keyCode) {
        //close showShortcodesPanel
        this.showShortcodesPanel = false;
        return;
      }

      if (this.showShortcodesPanel && enter == event.keyCode) {
        event.preventDefault();
        this.$refs.shortcodesListRef.onSelect();
        return;
      }

      if (this.showShortcodesPanel && [up, down].includes(event.keyCode)) {
        event.preventDefault();
        this.$refs.shortcodesListRef.onSelectMove(event.keyCode === up ? 'up' : 'down');
      }
    },
    onShortcodeSelected(shortcode) {
      this.message = shortcode;
      this.showShortcodesPanel = false;
      this.$nextTick(() => {
        this.$refs.messageInputRef.focus();
        this.onInput();
      });
    },
    onFilesModalCancel() {
      this.showFilesModal = false;
    },
    onFilesModalAdd(files) {
      //vefiy if the file is already in the list and add it
      files.forEach(file => {
        if (!this.files.find(f => f.url === file.url)) {
          this.files.push(file);
        }
      });
      this.showFilesModal = false;
    },
    removeFile(file) {
      this.files = this.files.filter(f => f.url !== file.url);
    },
    onNotesModalCancel() {
      this.showNotesModal = false;
    },
    onNotesModalAdd(note) {
      this.showNotesModal = false;
    },
  },
};
</script>
<style scoped lang="scss">
$textarea-height: 36px;

.message-field-wrapper {
  position: relative;
  margin-right: 0.5rem;
  width: 100%;

}
.chat-input-wrapper {
  border-radius: 20px;
  background: white;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  overflow: hidden;
}
.chat-input {
  position: relative;
  width: 100%;
  resize: none;
  transition: height 0.3s;
}


.input-bottom-info {
  background-color: #f4f4f4;
  // border-top: 1px solid #e0e0e0;
  padding: 0 0.5rem;
  font-size: 0.75rem;
}

.invisible {
  display: none;
}

.chat-input:focus {
  border: 1px solid var(--v-primary-lighten3);
}

.input-top-info {
}

</style>