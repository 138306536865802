<template>
  <v-row>
    <v-col cols="12" md="6">
      <div class="pa-4" :class="{ 'text-center': $vuetify.breakpoint.mdAndUp }">
        <div class="text-body-2">
          <v-icon
            size="32"
            v-if="config.useLocalStorage"
            class="green--text mr-1"
          >
            mdi-account-clock
          </v-icon>
          <v-icon v-else size="32" class="error--text mr-1">
            mdi-account-off-outline
          </v-icon>
          <div class="text-body-2 grey--text text--darken-2 font-weight-bold">
            {{
              config.useLocalStorage
                ? $t('editor.widget.behavior.userDataInfo')
                : $t('editor.widget.behavior.noUserDataInfo')
            }}
          </div>
          <span class="text-body-2 grey--text text--darken-2">
            {{
              config.useLocalStorage
                ? $t('editor.widget.behavior.userDataInfoSub')
                : $t('editor.widget.behavior.noUserDataInfoSub')
            }}
          </span>
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      :class="[$vuetify.breakpoint.smAndDown && 'mt-n4 pt-0']"
    >
      <v-card-title>
        {{ $t('editor.widget.behavior.titleRemember') }}
      </v-card-title>
      <div class="px-4">
        <div>
          <v-switch
            :input-value="config.useLocalStorage"
            :color="config.useLocalStorage ? 'green' : 'grey'"
            inset
            :label="$t('editor.widget.behavior.switchRememberLabel')"
            @change="toggleActive"
          />
        </div>
        <div>
          <v-select
            v-if="isTokenCustom"
            :value="true"
            :label="`${$t('editor.widget.behavior.selectUserLabel')} (${$t(
              'common.custom'
            )})`"
            :items="optionsWidgetCustom"
            item-text="title"
            item-value="value"
            prepend-inner-icon="mdi-account-clock-outline"
            disabled
          />
          <v-select
            v-else
            v-model="config.tokenLifetime"
            :label="$t('editor.widget.behavior.selectUserLabel')"
            :items="optionsWidget"
            item-text="title"
            item-value="value"
            prepend-inner-icon="mdi-account-clock-outline"
          />
        </div>
        <div>
          <v-select
            v-if="isCloseCustom"
            :value="true"
            :label="`${$t('editor.widget.behavior.selectCloseLabel')} (${$t(
              'common.custom'
            )})`"
            :items="optionsCloseCustom"
            item-text="title"
            item-value="value"
            prepend-inner-icon="mdi-close-box-multiple-outline"
            disabled
          />
          <v-select
            v-else
            v-model="config.dontShowClosedTeaserFor"
            :label="$t('editor.widget.behavior.selectCloseLabel')"
            :items="optionsClose"
            item-text="title"
            item-value="value"
            prepend-inner-icon="mdi-close-box-multiple-outline"
          />
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { getTimeLabel } from '@/utils/date';
const periods = {
  NEVER: 0,
  MINUTES15: 900000,
  MINUTES30: 1800000,
  HOUR1: 3600000,
  HOURS2: 7200000,
  HOURS4: 14400000,
  HOURS24: 86400000,
  DAYS4: 345600000,
  DAYS30: 2592000000,
};

export default {
  name: 'WidgetRememberUser',
  props: {
    config: {
      type: Object,
    },
  },
  data() {
    return {
      periods,
      selectedUserPeriod: periods.NEVER,
      selectedClosePeriod: periods.NEVER,
    };
  },
  computed: {
    optionsWidget() {
      if (!this.config?.useLocalStorage) {
        return [
          {
            title: this.$t('editor.widget.behavior.optionsWidget.never'),
            value: periods.NEVER,
          },
        ];
      }
      return [
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '2',
          }),
          value: periods.HOURS2,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '4',
          }),
          value: periods.HOURS4,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '24',
          }),
          value: periods.HOURS24,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xDays', {
            days: '4',
          }),
          value: periods.DAYS4,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xDays', {
            days: '30',
          }),
          value: periods.DAYS30,
        },
      ];
    },
    optionsWidgetCustom() {
      const title = getTimeLabel(this.config.tokenLifetime, this);
      return [
        {
          title,
          value: true,
        },
      ];
    },
    isTokenCustom() {
      if (!this.config.useLocalStorage) return false;

      // analog to optionsWidget
      const periodsList = [
        periods.NEVER,
        periods.HOURS2,
        periods.HOURS4,
        periods.HOURS24,
        periods.DAYS4,
        periods.DAYS30,
      ];
      return periodsList.includes(this.config.tokenLifetime) ? false : true;
    },

    optionsClose() {
      if (!this.config?.useLocalStorage) {
        return [
          {
            title: this.$t('editor.widget.behavior.optionsWidget.never'),
            value: periods.NEVER,
          },
        ];
      }
      return [
        {
          title: this.$t('editor.widget.behavior.optionsWidget.never'),
          value: periods.NEVER,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xMinutes', {
            minutes: '15',
          }),
          value: periods.MINUTES15,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xMinutes', {
            minutes: '30',
          }),
          value: periods.MINUTES30,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.hour1'),
          value: periods.HOUR1,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '2',
          }),
          value: periods.HOURS2,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '4',
          }),
          value: periods.HOURS4,
        },
        {
          title: this.$t('editor.widget.behavior.optionsWidget.xHours', {
            hours: '24',
          }),
          value: periods.HOURS24,
        },
      ];
    },
    optionsCloseCustom() {
      const title = getTimeLabel(this.config.dontShowClosedTeaserFor, this);
      return [
        {
          title,
          value: true,
        },
      ];
    },
    isCloseCustom() {
      if (!this.config.useLocalStorage) return false;

      // analog to optionsClose
      const closedList = [
        periods.NEVER,
        periods.MINUTES15,
        periods.MINUTES30,
        periods.HOUR1,
        periods.HOURS2,
        periods.HOURS4,
        periods.HOURS24,
      ];
      return closedList.includes(this.config.dontShowClosedTeaserFor)
        ? false
        : true;
    },
  },
  methods: {
    toggleActive() {
      this.config.useLocalStorage = !this.config.useLocalStorage;

      switch (this.config.useLocalStorage) {
        case true:
          this.config.tokenLifetime = periods.HOURS2;
          this.config.dontShowClosedTeaserFor = periods.HOURS2;
          break;
        case false:
        default:
          this.config.tokenLifetime = periods.NEVER;
          this.config.dontShowClosedTeaserFor = periods.NEVER;
          break;
      }
    },
  },
};
</script>
