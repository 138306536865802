var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-row", [
    _c(
      "div",
      { staticClass: "px-2 pb-2 grow max-width-full scrollable-content" },
      [
        _c(
          "div",
          { staticClass: "primary--text text--lighten-2 mb-1" },
          [
            _c(
              "v-icon",
              { attrs: { size: "16", color: "primary lighten-2" } },
              [
                _vm._v(
                  " " + _vm._s(_vm.infoCardIcon(_vm.infoTagTypes.form)) + " "
                ),
              ]
            ),
            _vm._v(" " + _vm._s(_vm.activeChat.lastForm.displayName) + " "),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex gap-2 flex-wrap mb-4" },
          _vm._l(_vm.formattedFormSteps, function (step, i) {
            return _c("LivechatInfoCard", { key: i, attrs: { item: step } })
          }),
          1
        ),
        _c(
          "div",
          { staticClass: "d-flex gap-2 flex-wrap mb-4" },
          _vm._l(_vm.liveChatAttachment, function (step, i) {
            return _c("LivechatInfoCard", { key: i, attrs: { item: step } })
          }),
          1
        ),
        _vm.attachmentItems?.length
          ? [
              _c("h2", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("common.attachments"))),
              ]),
              _c(
                "div",
                _vm._l(_vm.attachmentItems, function (item, index) {
                  return _c("FileCard", {
                    key: index,
                    attrs: { border: "", item: item },
                  })
                }),
                1
              ),
            ]
          : _vm._e(),
        _vm.activeChat.notes
          ? _c(
              "div",
              {
                staticClass:
                  "full-width pa-2 internal-note primary--text text-lighten-3",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center text-sm-body-2" },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          small: "",
                          left: "",
                          color: "primary lighten-3",
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.infoCardIcon(_vm.infoTagTypes.note)) +
                            " "
                        ),
                      ]
                    ),
                    _c("span", [_vm._v("Internal note")]),
                  ],
                  1
                ),
                _vm._v(" " + _vm._s(_vm.activeChat.notes) + " "),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }