<template>
  <HubBox
    v-if="sampleStrings && sampleStrings.length > 0"
    icon="chat_bubble_outline"
    :title="$t('intents.samples')"
    :boxId="boxId"
    expandedInitial
    @onExpand="(isExpanded) => isExpanded && trackIt('open-intent-samples')"
  >
    <div class="sample-questions">
      <v-card
        v-for="(sample, index) in sampleStrings"
        :key="index"
        class="rounded-lg elevation-0"
        color="grey lighten-3"
      >
        <v-card-text class="pa-2 primary--text">
          {{ sample }}
        </v-card-text>
      </v-card>
    </div>
    <div v-if="isAutoTranslated(intent.intent, user.language)" class="text-body-2 grey--text pl-6">
      {{ $t('common.autoTranslated') }}
    </div>

    <div class="d-flex gap-2 flex-wrap">
      <v-btn class="mt-4" color="primary lighten-3" outlined @click="isAddSampleOpen = true">
        <span>{{ $t('common.add') }}</span>
        <v-icon right>mdi-plus</v-icon>
      </v-btn>

      <v-btn :to="samplesLink" class="mt-4" color="primary lighten-3" outlined>
        <span>{{ $t('editor.samples.manage') }}</span>
        <v-icon right>list</v-icon>
      </v-btn>
    </div>

    <div v-if="showInsightsButton" class="pt-4">
      <v-divider class="mb-4"></v-divider>
      <div class="d-flex gap-3 items-center">
        <v-btn outlined color="primary lighten-3" :to="`/conversations?intent=${intent.intent}`">Insights
          <v-icon right>mdi-forum-outline</v-icon>
        </v-btn>
        <div class="text-body-2 primary--text text--lighten-2">
          {{ $t('intents.insightsDescription') }}
        </div>
      </div>
    </div>

    <AddSampleDialog
      :intent="intent"
      :isDialogOpen="isAddSampleOpen"
      @update:isDialogOpen="isAddSampleOpen = $event"
      @onSave="refreshSamples"
    />

  </HubBox>
</template>
<script>
import HubBox from '@/components/hub/HubBox.vue';
import { mapGetters } from 'vuex';
import ProductService from '@/services/product';
import { TrackingService } from '@/services/tracking';
import AddSampleDialog from '@/components/intent/components/AddSampleDialog.vue';

export default {
  name: 'IntentSamples',
  components: {
    HubBox,
    AddSampleDialog,
  },
  data() {
    return {
      isAddSampleOpen: false,
      aiFeedbackSamples: [],
      sampleStrings: [], // All samples as [String]
    };
  },
  props: {
    intent: {
      type: Object,
      required: true,
    },
    boxId: {
      type: String,
      default: 'IntentSamples',
    },
  },
  computed: {
    ...mapGetters('bots', ['currentBotId']),
    ...mapGetters('auth', ['user']),
    ...mapGetters('intents', ['samples', 'isAutoTranslated']),
    getSamples() {
      return this.samples(this.intent.intent, this.user.language);
    },
    showInsightsButton() {
      // if intent is set and intent is SimpleInformation template and intent is not in review or a suggestion
      return this.intent && this.intent.template === 'SimpleInformation' && !this.intent.suggested && !this.intent.payload?.inReview;
    },
    samplesLink() {
      return {
        name: 'intent-view',
        // params: { ...this.$route.params, view: 'tree', },
        params: {
          name: this.intent.intent,
          intent: this.intent,
          view: 'samples',
          backUrl: '/intents'
        },
      }
    },
  },
  methods: {
    async refreshSamples() {
      const samples = this.getSamples?.map(e => e) || []; // copy trainingData string

      let aiFeedback = await this.fetchSamples();
      
      if (aiFeedback && Array.isArray(aiFeedback)) {
        aiFeedback = aiFeedback.map(sample => sample.text);
      } else {
        aiFeedback = [];
      }

      // We add only 1 object of Ai Feedback and all other will be displayed with "+n Samples" , where 
      if (aiFeedback.length) {
        samples.push(aiFeedback[0]);
        if (aiFeedback.length > 1) {
          // "+#N sample(s)"
          const string = `+${aiFeedback.length - 1} ${ this.$tc('editor.samples.samples', aiFeedback.length - 1) }`
          samples.push(string)
        }
      }

      this.sampleStrings = samples;
    },
    async fetchSamples() {
      const { success, data, error } = await ProductService.getNlpFeedbacks(this.currentBotId, this.intent.intent);
      let aiFeedbackSamples = data || []; 
      // remove negative samples
      aiFeedbackSamples = aiFeedbackSamples.filter(sample => sample.feedback === true || sample.reference);
      return aiFeedbackSamples;
    },
    trackIt(type) {
      TrackingService.trackIntentsView({
        botId: this.currentBotId,
        type,
        source: 'IntentSamples',
      });
    },
  },
  async beforeMount() {
    await this.refreshSamples();
  },
};
</script>

<style scoped>
.sample-questions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>