import axios from 'axios';
import { TokenService } from './token';
import store from '../store/index';

const ApiService = {
  get baseURL() {
    return axios.defaults.baseURL;
  },

  _401interceptor: null, // 401 interceptor

  init(baseURL) {
    axios.defaults.baseURL = baseURL;

    // if environment is not production, set x-moinai-dev header
    if (process.env.NODE_ENV !== 'production') {
      axios.defaults.headers.common['x-moinai-dev'] = process.env.VUE_APP_HEADER_SECRET;
    }
  },

  setHeader() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${TokenService.getToken()}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data, timeout = 60000) {
    return axios.post(resource, data, {timeout});
  },

  patch(resource, data, timeout = 60000) {
    return axios.patch(resource, data, {timeout});
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource, data) {
    return axios.delete(resource, {
      headers: {
        Authorization: `Bearer ${TokenService.getToken()}`,
      },
      data: data,
    });
  },
  postData(ressource, formData) {
    return axios.post(ressource,
      formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  },

  /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
  customRequest(data) {
    return axios(data);
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {

        // if version txt file fetching failed, do nothing
        if (error && error.config && error.config.url === `${process.env.VUE_APP_HUB_URL}/version.txt`) {
          // do nothing
          return;
        }

        // Call "await this.get(`/bot`)" will change the Bot if we receive Error from backend request.
        // After Error Middleware has been added to back end. We can handle code errors here:
        // error.response.data should have { code: number, message: string, status: httpStatus }
        if (error.response?.data) {
          const { code, message, status } = error.response.data;

          // Return direct response when we have all 3 parameters
          if (error.response.data?.hasOwnProperty('code') && message && status) {
            throw error.response.data;
            // return Promise.reject({ code, message, status });
          } 
        }

        if (!error.response) {
          setTimeout(() => store.dispatch('connectionLost'), 1.5 * 1000);
        } else if (error.request.status == 401) {
          if (error.config?.url === `/bot`) {
            // Logout the user
            store.dispatch('auth/logout');
            throw error;
          } else {
            await this.get(`/bot`);
          }
        } else if (error.request.status === 400) {
          return store.dispatch('bots/check');
        }

        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;
