var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("editor.samples.add"),
            "primary-icon": "save",
            "primary-label": _vm.$t("common.add"),
            "secondary-icon": "close",
            "secondary-label": _vm.$t("common.cancel"),
            loading: _vm.isLoading,
            disabled: _vm.isLoading || !_vm.isNewValueValid(),
            asyncDialog: "",
            modal: "",
          },
          on: {
            onPrimary: _vm.addNewSample,
            onSecondary: function ($event) {
              return _vm.toggleDialog(false)
            },
          },
          model: {
            value: _vm.isDialogOpen,
            callback: function ($$v) {
              _vm.isDialogOpen = $$v
            },
            expression: "isDialogOpen",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-10" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("v-textarea", {
                        attrs: {
                          label: _vm.$tc("editor.samples.samples", 1),
                          outlined: "",
                          rules: _vm.rules.newSampleRule,
                          rows: "5",
                        },
                        model: {
                          value: _vm.newSample,
                          callback: function ($$v) {
                            _vm.newSample = $$v
                          },
                          expression: "newSample",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }