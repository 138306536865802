var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.sampleStrings && _vm.sampleStrings.length > 0
    ? _c(
        "HubBox",
        {
          attrs: {
            icon: "chat_bubble_outline",
            title: _vm.$t("intents.samples"),
            boxId: _vm.boxId,
            expandedInitial: "",
          },
          on: {
            onExpand: (isExpanded) =>
              isExpanded && _vm.trackIt("open-intent-samples"),
          },
        },
        [
          _c(
            "div",
            { staticClass: "sample-questions" },
            _vm._l(_vm.sampleStrings, function (sample, index) {
              return _c(
                "v-card",
                {
                  key: index,
                  staticClass: "rounded-lg elevation-0",
                  attrs: { color: "grey lighten-3" },
                },
                [
                  _c("v-card-text", { staticClass: "pa-2 primary--text" }, [
                    _vm._v(" " + _vm._s(sample) + " "),
                  ]),
                ],
                1
              )
            }),
            1
          ),
          _vm.isAutoTranslated(_vm.intent.intent, _vm.user.language)
            ? _c("div", { staticClass: "text-body-2 grey--text pl-6" }, [
                _vm._v(" " + _vm._s(_vm.$t("common.autoTranslated")) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex gap-2 flex-wrap" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: { color: "primary lighten-3", outlined: "" },
                  on: {
                    click: function ($event) {
                      _vm.isAddSampleOpen = true
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("common.add")))]),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("mdi-plus")]),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mt-4",
                  attrs: {
                    to: _vm.samplesLink,
                    color: "primary lighten-3",
                    outlined: "",
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("editor.samples.manage")))]),
                  _c("v-icon", { attrs: { right: "" } }, [_vm._v("list")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showInsightsButton
            ? _c(
                "div",
                { staticClass: "pt-4" },
                [
                  _c("v-divider", { staticClass: "mb-4" }),
                  _c(
                    "div",
                    { staticClass: "d-flex gap-3 items-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            outlined: "",
                            color: "primary lighten-3",
                            to: `/conversations?intent=${_vm.intent.intent}`,
                          },
                        },
                        [
                          _vm._v("Insights "),
                          _c("v-icon", { attrs: { right: "" } }, [
                            _vm._v("mdi-forum-outline"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-body-2 primary--text text--lighten-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("intents.insightsDescription")) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("AddSampleDialog", {
            attrs: { intent: _vm.intent, isDialogOpen: _vm.isAddSampleOpen },
            on: {
              "update:isDialogOpen": function ($event) {
                _vm.isAddSampleOpen = $event
              },
              onSave: _vm.refreshSamples,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }