var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "HubDialog",
        {
          attrs: {
            title: _vm.$t("botFormEdit.addFormPart"),
            "primary-label": _vm.$t("common.ok"),
            "primary-icon": "check",
            "secondary-label": _vm.$t("common.cancel"),
            "secondary-icon": "close",
          },
          on: {
            onPrimary: function ($event) {
              return _vm.addStep()
            },
            onSecondary: function ($event) {
              _vm.createDialog = false
              _vm.$refs.form.reset()
            },
          },
          model: {
            value: _vm.createDialog,
            callback: function ($$v) {
              _vm.createDialog = $$v
            },
            expression: "createDialog",
          },
        },
        [
          _vm.stepAfter
            ? _c("div", { staticClass: "my-4" }, [
                _c("div", { staticClass: "text-body-1" }, [
                  _vm._v(" " + _vm._s(_vm.$t("botFormEdit.pasteAfter")) + " "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v('"' + _vm._s(_vm.stepAfter.displayName) + '"'),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.createDialogValid,
                    callback: function ($$v) {
                      _vm.createDialogValid = $$v
                    },
                    expression: "createDialogValid",
                  },
                },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("botFormEdit.whatIsAsked"),
                      required: "",
                      rules: _vm.newNameRules,
                      counter: 20,
                    },
                    model: {
                      value: _vm.newName,
                      callback: function ($$v) {
                        _vm.newName = $$v
                      },
                      expression: "newName",
                    },
                  }),
                  _c("v-select", {
                    attrs: {
                      label: _vm.$t("botFormEdit.typeOfInput"),
                      "item-text": "name",
                      "item-value": "template",
                      "item-disabled": "isDisabled",
                      items: _vm.formChildTypes,
                    },
                    model: {
                      value: _vm.newType,
                      callback: function ($$v) {
                        _vm.newType = $$v
                      },
                      expression: "newType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.isAdmin &&
      !_vm.isLoading &&
      _vm.content &&
      !_vm.noChannelContent &&
      _vm.content.childBoxes &&
      _vm.content.childBoxes.length === 0
        ? _c(
            "v-card",
            { staticClass: "ma-0 rounded-0", attrs: { flat: "" } },
            [
              _c("v-card-subtitle", { staticClass: "admin--text" }, [
                _vm._v(" " + _vm._s(_vm.$t("botFormEdit.noFormPart")) + " "),
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.$t("botFormEdit.formPartInfo")) + " "),
                _c("ul", [
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("botFormEdit.freeTextInfo"))),
                  ]),
                  _c("li", [_vm._v(_vm._s(_vm.$t("botFormEdit.eMailInfo")))]),
                  _c("li", [
                    _vm._v(_vm._s(_vm.$t("botFormEdit.multipleInfo"))),
                  ]),
                ]),
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "primary", outlined: "", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.createDialog = true
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("botFormEdit.createStep")) + " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.noChannelContent
        ? _c(
            "v-list",
            { staticClass: "mt-0", attrs: { color: "#f5f5f5" } },
            [
              _c(
                "v-list-item",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-card",
                        { attrs: { outlined: "" } },
                        [
                          _c(
                            "v-alert",
                            {
                              attrs: {
                                color: "primary lighten-3",
                                dark: "",
                                type: "error",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("editor.common.noContentFound")
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                          _vm.noChannelContent
                            ? _c(
                                "v-row",
                                {
                                  attrs: {
                                    "no-gutters": "",
                                    justify: "center",
                                  },
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          src: "https://uploads-ssl.webflow.com/5e71ed18ed23ac1f5bb16bc5/5fd9c953ae22b9695c62f833_404_roboter.png",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.content && !_vm.noChannelContent
        ? _c(
            "v-list",
            { staticClass: "py-0", attrs: { color: "#f5f5f5" } },
            _vm._l(_vm.formSteps, function (step, index) {
              return _c(
                "v-list-item",
                {
                  key: step.box,
                  ref: step.box,
                  refInFor: true,
                  staticClass: "px-0",
                },
                [
                  _c(
                    "v-list-item-content",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-card",
                        { staticClass: "rounden-0", attrs: { flat: "" } },
                        [
                          _c(
                            "v-timeline",
                            {
                              staticClass: "mr-6",
                              attrs: { "align-top": "", dense: "" },
                            },
                            [
                              _c(
                                "v-timeline-item",
                                {
                                  staticClass: "mb-3 pb-3",
                                  attrs: { "fill-dot": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function () {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "white--text text-h6",
                                              },
                                              [_vm._v(_vm._s(index + 1))]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm.isAdmin
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "float-right",
                                          attrs: {
                                            depressed: "",
                                            color: "admin",
                                            outlined: "",
                                            small: "",
                                            disabled: !_vm.stepDeletable(step),
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteStep(step, index)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("botFormEdit.deleteStep")
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass: "ml-2",
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("delete")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "span",
                                    { staticClass: "text-h6 text-h5" },
                                    [_vm._v(_vm._s(step.displayName))]
                                  ),
                                  _c("p", { staticClass: "text-body-2" }, [
                                    _vm._v(
                                      _vm._s(_vm.stepNames(step.template).name)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              step.template === "BotFormYesNo"
                                ? _c("BotFormYesNoEdit", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormMultipleChoice"
                                ? _c("BotFormMultipleChoiceEdit", {
                                    key: step.box,
                                    attrs: {
                                      box: step.box,
                                      name: step.displayName,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: {
                                      onUpdated: _vm.updateAnswer,
                                      addMultiStep: _vm.addMultiStep,
                                      removeMultiStep: _vm.removeMultiStep,
                                    },
                                  })
                                : _vm._e(),
                              step.template === "BotFormMultipleChoiceSlider"
                                ? _c("BotFormMultipleChoiceSliderEdit", {
                                    key: step.box,
                                    attrs: {
                                      box: step.box,
                                      name: step.displayName,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: {
                                      onUpdated: _vm.updateAnswer,
                                      addMultiStep: _vm.addMultiStep,
                                      removeMultiStep: _vm.removeMultiStep,
                                    },
                                  })
                                : _vm._e(),
                              step.template === "BotFormFreeText"
                                ? _c("BotFormFreeTextEdit", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormEmail"
                                ? _c("BotFormEmailEdit", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormValidableInput"
                                ? _c("BotFormValidableInputEdit", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormFile"
                                ? _c("BotFormFileEdit", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormActionView"
                                ? _c("BotFormActionView", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: { onUpdated: _vm.updateAnswer },
                                  })
                                : _vm._e(),
                              step.template === "BotFormConditionalSwitch"
                                ? _c("BotFormConditionalSwitch", {
                                    key: step.box,
                                    attrs: {
                                      name: step.displayName,
                                      box: step.box,
                                      channel: _vm.channel,
                                      intent: _vm.content.intent,
                                    },
                                    on: {
                                      onUpdated: _vm.updateAnswer,
                                      addMultiStep: _vm.addMultiStep,
                                    },
                                  })
                                : _vm._e(),
                              _vm.isAdmin &&
                              (step.template === "BotFormMultipleChoice" ||
                                step.template === "BotFormYesNo" ||
                                step.template ===
                                  "BotFormMultipleChoiceSlider" ||
                                step.template === "BotFormConditionalSwitch") &&
                              _vm.content.template === "BotForm"
                                ? _c(
                                    "v-timeline-item",
                                    { attrs: { "hide-dot": "" } },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          label: _vm.$t("botFormEdit.nextStep"),
                                          items: _vm.getNextStepSelect(step),
                                          "item-value": "box",
                                          value: _vm.getSelectedValue(step).box,
                                          "item-text": "displayName",
                                          disabled:
                                            !_vm.isBoxUsedMultiple(step),
                                        },
                                        on: {
                                          change: (e) => {
                                            _vm.changeNextStepSelect(step, e)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              step.next &&
                              ((step.next.startsWith("completion") &&
                                _vm.isAdmin) ||
                                step.next.startsWith("completion("))
                                ? _c(
                                    "v-timeline-item",
                                    {
                                      staticClass: "mb-3 pb-3",
                                      attrs: { color: "white", "fill-dot": "" },
                                    },
                                    [
                                      _c("p", [
                                        _vm._v(
                                          " Soll das Formular beendet werden oder zu einem anderen Thema oder Formular verlassen werden. "
                                        ),
                                      ]),
                                      _c(
                                        "v-row",
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  disabled: !_vm.isAdmin,
                                                  "input-value":
                                                    _vm.completionIntent !==
                                                    "none",
                                                  label: "Verlassen",
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeCompletionMode,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            [
                                              _c("v-select", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      _vm.completionIntent !==
                                                      "none",
                                                    expression:
                                                      "completionIntent !== 'none'",
                                                  },
                                                ],
                                                attrs: {
                                                  disabled: !_vm.isAdmin,
                                                  label: "Formular oder Thema",
                                                  items: _vm.otherIntents,
                                                  "item-value": "intent",
                                                  value: _vm.completionIntent,
                                                  "item-text": "displayName",
                                                },
                                                on: {
                                                  change:
                                                    _vm.changeCurrentCompletionIntent,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.isAdmin && _vm.content.template === "BotForm"
                        ? _c(
                            "v-row",
                            { staticClass: "mt-2 ml-2 pb-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "admin white--text",
                                    depressed: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.stepAfter = step
                                      _vm.createDialog = true
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("botFormEdit.newStep")) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("add")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm.isAdmin &&
                          _vm.content.template === "BotFormRating" &&
                          _vm.formSteps.length === 1
                        ? _c(
                            "v-row",
                            { staticClass: "mt-2 ml-2 pb-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "primary text--lighten-3",
                                    depressed: "",
                                    small: "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.stepAfter = step
                                      _vm.addRatingCommentStep()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("botFormEdit.addCommentStep")
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { small: "" },
                                    },
                                    [_vm._v("add")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.content &&
      !_vm.noChannelContent &&
      !_vm.isLoading &&
      _vm.completionIntent === "none"
        ? _c(
            "v-card",
            { staticClass: "rounded-0", attrs: { flat: "" } },
            [
              _c(
                "v-timeline",
                { staticClass: "mr-6", attrs: { "align-top": "", dense: "" } },
                [
                  _c(
                    "v-timeline-item",
                    {
                      attrs: { color: "success", "fill-dot": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v("check"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1004668577
                      ),
                    },
                    [
                      _c("span", { staticClass: "text-h6 text-h5" }, [
                        _vm._v(_vm._s(_vm.$t("botFormEdit.endForm"))),
                      ]),
                    ]
                  ),
                  _vm.content && _vm.content.completion
                    ? _c("ActionList", {
                        attrs: {
                          actions: _vm.content.completion.actions,
                          channelType: _vm.channelType,
                        },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                  _vm.isAdmin && _vm.webhookConfigsList.length && false
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { color: "white" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "admin" } },
                                      [_vm._v("cable")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3794627622
                          ),
                        },
                        [
                          _c("v-select", {
                            attrs: {
                              label: _vm.$t("botFormEdit.chooseWebhook"),
                              items: _vm.webhookConfigsList,
                              "item-text": "displayName",
                              "item-value": "key",
                              value: _vm.selectedWebhook,
                            },
                            on: { change: _vm.selectWebhook },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isAdmin
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { "fill-dot": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "admin" } },
                                      [_vm._v("forward_to_inbox")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3854381981
                          ),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "moin-admin-box pa-1" },
                            [
                              _c("v-select", {
                                attrs: {
                                  label: _vm.$t("botFormEdit.finalizeForm"),
                                  items: _vm.notificationTypes,
                                  "item-text": "displayName",
                                  "item-value": "name",
                                  "item-disabled": "isDisabled",
                                  value: _vm.notificationType.name,
                                },
                                on: { input: _vm.updateNotifcation },
                              }),
                              _vm.notificationType.name === "webchat_cmd"
                                ? _c("v-select", {
                                    attrs: {
                                      label: _vm.$t(
                                        "botFormEdit.livechatVendor"
                                      ),
                                      items: _vm.livechatTypes,
                                      "item-text": "displayName",
                                      "item-value": "name",
                                      disabled: _vm.isHighPrivacy,
                                      value:
                                        _vm.notificationType.parameters.payload
                                          .type,
                                    },
                                    on: { input: _vm.updateLivechatType },
                                  })
                                : _vm._e(),
                              _vm.notificationType.name === "greyhound" ||
                              _vm.notificationType.name === "zChat" ||
                              _vm.notificationType.name === "livechat" ||
                              _vm.notificationType.name === "salesforce" ||
                              _vm.notificationType.name === "freshchat" ||
                              _vm.notificationType.name === "crisp"
                                ? _c("v-select", {
                                    attrs: {
                                      label: _vm.$t(
                                        "botFormEdit.livechatVendor"
                                      ),
                                      items: _vm.livechatTypes,
                                      "item-text": "displayName",
                                      "item-value": "name",
                                      disabled: _vm.isHighPrivacy,
                                      value: _vm.notificationType.name,
                                    },
                                    on: { input: _vm.updateLivechatType },
                                  })
                                : _vm._e(),
                              _vm.notificationType.name === "moin_livechat"
                                ? _c("v-select", {
                                    attrs: {
                                      items: _vm.possibleLivechatGroups,
                                      rules: _vm.chatGroupRules,
                                      label: _vm.$t(
                                        "botFormEdit.directToLivechatGroups"
                                      ),
                                      multiple: "",
                                      chips: "",
                                    },
                                    on: { input: _vm.updateLivechatGroup },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function ({ item }) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "group-list-item",
                                                  class: {
                                                    deletedGroup:
                                                      item.isDeletedGroup,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.text) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1763752622
                                    ),
                                    model: {
                                      value: _vm.toMoinLivechatGroups,
                                      callback: function ($$v) {
                                        _vm.toMoinLivechatGroups = $$v
                                      },
                                      expression: "toMoinLivechatGroups",
                                    },
                                  })
                                : _vm._e(),
                              _vm.isAdmin &&
                              _vm.notificationType.name === "notification"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "botFormEdit.eMailTemplate"
                                      ),
                                    },
                                    on: { input: _vm.updateAnswer },
                                    model: {
                                      value:
                                        _vm.notificationType.parameters.type,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificationType.parameters,
                                          "type",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notificationType.parameters.type",
                                    },
                                  })
                                : _vm._e(),
                              _vm.isAdmin &&
                              _vm.notificationType.name === "salesforce_case"
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "ma-0 d-flex justify-start align-center",
                                    },
                                    [
                                      _c("label", { staticClass: "mr-3" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "botFormEdit.fileUpload.maxFilesLabel"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "botFormEdit.fileUpload.maxFilesTemplate"
                                          ),
                                          type: "number",
                                          step: "1",
                                          min: "0",
                                          max: "10",
                                        },
                                        on: { input: _vm.updateAnswer },
                                        model: {
                                          value:
                                            _vm.notificationType.parameters
                                              .maxFiles,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificationType.parameters,
                                              "maxFiles",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "notificationType.parameters.maxFiles",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.isAdmin &&
                              _vm.notificationType.name === "$zendesk"
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass:
                                        "ma-0 d-flex justify-start align-center",
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: _vm.$t(
                                            "botFormEdit.zendesk.id"
                                          ),
                                        },
                                        on: { input: _vm.updateAnswer },
                                        model: {
                                          value:
                                            _vm.notificationType.parameters.id,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.notificationType.parameters,
                                              "id",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "notificationType.parameters.id",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.notificationType.name === "$handover" &&
                              _vm.isUserlike
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: _vm.$t(
                                        "botFormEdit.userLikeGroup"
                                      ),
                                    },
                                    on: { input: _vm.updateAnswer },
                                    model: {
                                      value:
                                        _vm.notificationType.parameters.group,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificationType.parameters,
                                          "group",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notificationType.parameters.group",
                                    },
                                  })
                                : _vm._e(),
                              _vm.notificationType.name === "webchat_cmd" &&
                              _vm.notificationType.parameters.payload.type ===
                                "zendesk"
                                ? _c("v-combobox", {
                                    attrs: {
                                      multiple: "",
                                      label: "Tags",
                                      "append-icon": "",
                                      chips: "",
                                      "deletable-chips": "",
                                    },
                                    on: { input: _vm.updateAnswer },
                                    model: {
                                      value:
                                        _vm.notificationType.parameters.payload
                                          .tags,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificationType.parameters
                                            .payload,
                                          "tags",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notificationType.parameters.payload.tags",
                                    },
                                  })
                                : _vm._e(),
                              _vm.notificationType.name === "webchat_cmd" &&
                              _vm.notificationType.parameters.payload.type ===
                                "zendesk"
                                ? _c("v-text-field", {
                                    attrs: {
                                      label: "Department Id",
                                      type: "number",
                                    },
                                    on: { input: _vm.updateAnswer },
                                    model: {
                                      value:
                                        _vm.notificationType.parameters.payload
                                          .default_department,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificationType.parameters
                                            .payload,
                                          "default_department",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression:
                                        "\n                notificationType.parameters.payload.default_department\n              ",
                                    },
                                  })
                                : _vm._e(),
                              _vm.notificationType.name === "webchat_cmd" &&
                              _vm.notificationType.parameters.payload.type ===
                                "zendesk"
                                ? _c("v-text-field", {
                                    attrs: { label: "Account Key" },
                                    on: { input: _vm.updateAnswer },
                                    model: {
                                      value:
                                        _vm.notificationType.parameters.payload
                                          .account_key,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.notificationType.parameters
                                            .payload,
                                          "account_key",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "notificationType.parameters.payload.account_key",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isAdmin && _vm.notificationType.name === "moin_livechat"
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: {
                            "fill-dot": "",
                            color: "white",
                            "hide-dot": !_vm.content.allowFileUpload?.enabled,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "success" } },
                                      [_vm._v("insert_drive_file")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            922128254
                          ),
                        },
                        [
                          _vm.notificationType.name === "moin_livechat"
                            ? _c(
                                "div",
                                {
                                  staticClass: "moin-admin-box pa-1 mt-4 px-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "flex-direction": "row",
                                        "align-items": "center",
                                      },
                                    },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          label: _vm.$t(
                                            "botFormEdit.fileUpload.duringLivechatOption"
                                          ),
                                          color: "green",
                                          inset: "",
                                          dense: "",
                                        },
                                        on: { change: _vm.setFileUploadOption },
                                        model: {
                                          value:
                                            _vm.content.allowFileUpload.enabled,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.content.allowFileUpload,
                                              "enabled",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "content.allowFileUpload.enabled",
                                        },
                                      }),
                                      _vm.content.allowFileUpload.enabled &&
                                      _vm.showFileUploadHint
                                        ? _c("InfoHoverBox", {
                                            staticClass: "ml-3",
                                            attrs: {
                                              top: -80,
                                              left: -110,
                                              title: _vm.$t("common.warning"),
                                              body: _vm.$t(
                                                "integrations.salesforce.case.hintFileUpload"
                                              ),
                                              "main-icon":
                                                "mdi-information-outline",
                                              "bounce-animation": true,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  !["notification"].includes(
                                    _vm.notificationType.name
                                  ) && _vm.content.allowFileUpload?.enabled
                                    ? _c("InfoBox", {
                                        staticClass: "my-4",
                                        attrs: {
                                          text: _vm.$t(
                                            "botFormEdit.fileUpload.duringLivechatInfo"
                                          ),
                                          icon: "mdi-exclamation-thick",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.content.allowFileUpload.enabled
                            ? _c("BotFormFileEditBase", {
                                staticClass: "mt-4",
                                attrs: {
                                  options: _vm.content.allowFileUpload.options,
                                },
                                on: { onUpdated: _vm.updateAnswer },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.content && _vm.isLivchatType
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { "fill-dot": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("support_agent")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1903123886
                          ),
                        },
                        [
                          _c("span", { staticClass: "text-h6 text-h5" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("botFormEdit.agentAvailable")) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.content && _vm.content.livechatStart && _vm.isLivchatType
                    ? _c("ActionList", {
                        attrs: {
                          actions: _vm.content.livechatStart.actions,
                          channelType: _vm.channelType,
                        },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                  _vm.content && _vm.isLivchatType
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { "fill-dot": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("hourglass_top")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1445155769
                          ),
                        },
                        [
                          _c("span", { staticClass: "text-h6 text-h5" }, [
                            _vm._v(_vm._s(_vm.$t("botFormEdit.agentWaiting"))),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.content &&
                  _vm.content.livechatAgentWaiting &&
                  _vm.isLivchatType
                    ? _c("ActionList", {
                        attrs: {
                          actions: _vm.content.livechatAgentWaiting.actions,
                          quickreplies: "",
                          channelType: _vm.channelType,
                        },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                  _vm.content && _vm.isLivchatType
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { "fill-dot": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("headset_off")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2538028317
                          ),
                        },
                        [
                          _c("span", { staticClass: "text-h6 text-h5" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("botFormEdit.noAgentAvailable")) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.content &&
                  _vm.content.livechatNoAgent &&
                  _vm.isLivchatType
                    ? _c("ActionList", {
                        attrs: {
                          actions: _vm.content.livechatNoAgent.actions,
                          quickreplies: "",
                          channelType: _vm.channelType,
                        },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                  _vm.livechatHasHandoverToBot
                    ? _c(
                        "v-timeline-item",
                        {
                          attrs: { "fill-dot": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "icon",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "white" } },
                                      [_vm._v("keyboard_return")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4144707967
                          ),
                        },
                        [
                          _c("span", { staticClass: "text-h6 text-h5" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("botFormEdit.agentGivesBack")) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.livechatHasHandoverToBot
                    ? _c("LivechatHandoverToBot", {
                        attrs: { actions: _vm.content.livechatFinish.actions },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.content && !_vm.noChannelContent && !_vm.isLoading
        ? _c(
            "v-card",
            {
              staticClass: "rounded-0",
              class: _vm.contentChanged ? "" : "mb-4",
              attrs: { flat: "" },
            },
            [
              _c(
                "v-timeline",
                { staticClass: "mr-6", attrs: { "align-top": "", dense: "" } },
                [
                  _c(
                    "v-timeline-item",
                    {
                      attrs: { color: "error", "fill-dot": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("v-icon", { attrs: { color: "white" } }, [
                                  _vm._v("close"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4211832241
                      ),
                    },
                    [
                      _c("span", { staticClass: "title text-h5" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("botFormEdit.textAfterCancel")) +
                            " "
                        ),
                      ]),
                    ]
                  ),
                  _vm.content && _vm.content.cancel
                    ? _c("ActionList", {
                        attrs: {
                          actions: _vm.content.cancel.actions,
                          channelType: _vm.channelType,
                        },
                        on: { onUpdate: _vm.updateAnswer },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.content && !_vm.noChannelContent && _vm.isLoading
        ? _c(
            "v-card",
            [_c("v-skeleton-loader", { attrs: { type: "article" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }